
    import DriftViewer from 'drift-zoom';
    import {ref, onMounted} from 'vue';
    

    export default ({
        props: {},
        data(){
            return {
            };
        },
        setup(){
          const driftDemoTrigger = ref(HTMLImageElement);
          const detailsContainer = ref(HTMLDivElement);

          onMounted(() => {
            let demoTrigger = driftDemoTrigger;
            let paneContainer = detailsContainer;
          
            let dvOptions: DriftViewer.Options = {
              inlinePane: 500,
              inlineOffsetY: -85,
              containInline: true,
              hoverBoundingBox: true,
              paneContainer: (paneContainer as any).value as HTMLElement
          };

          new DriftViewer((demoTrigger as any).value as HTMLElement, dvOptions);
          })
          return {driftDemoTrigger, detailsContainer};
        },
        
        mounted() {
          
        },
        methods: {
           
        }          
        
    });
