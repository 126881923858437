import {createApp} from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue';
import Home from './pages/Home.vue';
import SampleImageViewer from './pages/SampleImageViewer.vue';
let routes:any = [
    { path: '/', component: Home },
    { path: '/sample-image-viewer', component: SampleImageViewer },
  ]

const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})

const app = createApp(App as any)

app.use(router)
app.mount('#app')
