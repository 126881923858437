class AJAXProvider {
    private username = "apitest";
    private password = "nvk9FJM!ere0aqd1nfb";
    private jwt = "";
    private headers = new Headers();


    constructor(apiroot: string, readyCallback?: Function) {
        this._apiBase = apiroot;
        fetch(this._apiBase + "/api/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.username,
                password: this.password
            })
        }).then(resp => resp.json()).then(data => {this.jwt = data; this.headers.append('authorization', `Bearer ${data}`); readyCallback&& readyCallback()});
    }
    private _apiBase: string = "";
    public setApiBase(b: string) { this._apiBase = b; }
    private urlBase: string = "/api/";
    public post(urlPart: string, data: any, callback: Function) {
        fetch(this._apiBase + this.urlBase + urlPart, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            headers: [
                ["Content-Type", "application/json; charset=UTF-8"],
                ["Authorization", `Bearer ${this.jwt}`]
            ],
            body: JSON.stringify(data)
        }).then(resp => {
            if(!resp.ok){
                console.error("AJAX call was not successful.")
                return;
            }
            let ct = resp.headers.get("Content-Type");
            if(ct === "image/png" || ct === "image/jpeg") {
                return resp.arrayBuffer();
            } else {
                return resp.json();
            }
        }).then(data => callback(data));
    };
    private getOpts: RequestInit = {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
        headers: this.headers
    };
    private postOpts: RequestInit = {
        
        body: null
    };
    public get(urlPart: string, callback: Function){
        fetch(this._apiBase + this.urlBase + urlPart, this.getOpts).then(resp => {
            if(!resp.ok){
                console.error("AJAX call was not successful.")
            } else {
                return resp.json();
            }
        }).then((data) => {
            callback(data)
        });
    }
}

export default AJAXProvider;