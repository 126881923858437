
    import { defineComponent } from 'vue'
    import County from '@/dto/County';
    //import { file } from '@babel/types';
    //import { Component, Prop, Vue } from 'vue-property-decorator';
    import AJAXProvider from '../AJAXProvider';
    import CountyFile from '../dto/CountyFile';

    let ajaxProvider: AJAXProvider|null = null;

    let countyData: Array<County> = [
    ];

    export default defineComponent({
        props: {},
        data(){
            return {
                ajaxProvider: null,
                APIRoot: 'https://apidemo.msifla.net',
                startingPage: '',
                numPages: '',
                book: '',
                county: '',
                fileFormat: 'tiff',
                failure: false,
                file: new CountyFile(),
                counties: countyData,
                showPreviewImage : false,
                previewImage: "",
            };
        },
        mounted() {
          let vm = this;
          ajaxProvider = new AJAXProvider('https://apidemo.msifla.net', function(){
            ajaxProvider?.get('counties', function(data:any){
             vm.counties = data;
            });
          });
        },
        methods: {
            getLink(file: CountyFile): string {
                return this.APIRoot + "/api/Download/?fileName=" + file.DownloadFilename+"&authSig=" + file.AuthorizationSignature;
            },
            searchFiles() {
                ajaxProvider?.post('Build', {
                    county: this.county,
                    book: this.book,
                    startingPage: this.startingPage,
                    numPages: this.numPages,
                    fileFormat: this.fileFormat
                }, (resp: any) => {
                    if (!resp.BuildSuccess) {
                        this.failure = true;
                    } else {
                        this.failure = false;
                        this.file = resp
                    }
                    
                });
            },
            preview() {
              ajaxProvider?.post('Preview', {
                county: this.county, book: this.book, page: this.startingPage
              }, (data: any) => {
                var imageB64 = _arrayBufferToBase64(data);
                //debugger;
                this.previewImage = `data:image/png;base64,${imageB64}`;
                this.showPreviewImage = true;
              });
            }
        }          
        
    });
    function _arrayBufferToBase64( buffer: ArrayBuffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}
