import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../public/images/png/doc_low.png'


const _withScopeId = n => (_pushScopeId("data-v-378143b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "thumbnail-container" }
const _hoisted_2 = {
  class: "drift-demo-trigger",
  ref: "driftDemoTrigger",
  "data-zoom": "/images/png/doc_full.png",
  src: _imports_0
}
const _hoisted_3 = {
  id: "details-container",
  ref: "detailsContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", _hoisted_2, null, 512)
    ]),
    _createElementVNode("div", _hoisted_3, null, 512)
  ]))
}